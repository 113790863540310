<template>
  <div class="phone-find">
    <div class="content">
      <el-form ref="infos" :model="infos" :rules="rules" label-width="100px">
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="infos.phone" style="width: 300px;" @focus="phoneFocus" @blur="phoneBlur">请输入手机号</el-input>
        </el-form-item>
        <div style="display: flex;margin-bottom: 20px;">
          <el-form-item style="margin-bottom: 0;" label="验证码" prop="code">
            <el-input v-model="infos.code" placeholder="请输入验证码" style="width: 150px;margin-right: 12px;" @focus="codeFocus" @blur="codeBlur" />
          </el-form-item>
          <div style="width: 138px;" @click="getCode">
            <img v-if="image" class="image" :src="image" style="cursor: pointer;" alt="点击刷新">
            <el-button v-else type="text">点击刷新</el-button>
          </div>
        </div>
      </el-form>
      <div style="margin-top: 20px;padding-left: 100px;width: 400px;">
        <el-button type="primary" style="height: 40px;" :loading="confirmLoading" @click="confirm">确定</el-button>
      </div>
      <div class="phone-tip">
        <div>没收到短信验证码</div>
        <div>1. 网络通讯异常可能会造成短信丢失， 请重新获取或稍后再试</div>
        <div>2. 请核实手机是否已欠费停机，或者关机</div>
      </div>
    </div>
  </div>
</template>

<script>
import { captcha } from '@/api/user'
import { getFindPasswordSms } from '@/api/sms'
import { ElMessage } from 'element-plus'
export default {
  name: 'ChangeFindType',
  data() {
    const validateMobile = (rule, value, callback) => {
      const reg = new RegExp('^1[3-9]\\d{9}$')
      if (this.status || this.comstatus.phone) {
        if (value.length === 0) {
          callback(new Error('请输入手机号'))
        } else if (!reg.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (this.status || this.comstatus.code) {
        if (!value.trim()) {
          callback(new Error('验证码不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      confirmLoading: false,
      status: false,
      comstatus: {
        phone: false,
        code: false
      },
      infos: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [
          { required: true, validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { required: true, validator: validateCode, trigger: 'blur' }
        ]
      },
      image: '',
      keys: ''
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    // 用户光标聚焦
    phoneFocus() {
      this.comstatus.phone = false
      this.$refs.infos.clearValidate('phone')
    },
    // 用户光标离开
    phoneBlur() {
      this.status = false
      if (this.infos.phone !== '') {
        this.comstatus.phone = true
      }
    },
    // 用户光标聚焦
    codeFocus() {
      this.comstatus.code = false
      this.$refs.infos.clearValidate('code')
    },
    // 用户光标离开
    codeBlur() {
      this.status = false
      if (this.infos.code !== '') {
        this.comstatus.code = true
      }
    },
    confirm() {
      this.status = true
      this.$refs.infos.validate(valid => {
        if (valid) {
          const data = {
            account: this.$route.query.account.trim(),
            captchaKey: this.keys,
            code: this.infos.code.trim(),
            phone: this.infos.phone.trim()
          }
          this.confirmLoading = true
          getFindPasswordSms(data).then(res => {
            if (res.meta.status === 200) {
              this.$router.push('/personal-info/update-password?account=' + this.$route.query.account + '&phone=' + this.infos.phone + '&keys=' + this.keys + '&code=' + this.infos.code)
              setTimeout(() => {
                this.confirmLoading = false
              }, 350)
              // this.mobileForm.mobileCode = res.data
            } else {
              ElMessage.error(res.meta.msg)
              this.getCode()
              setTimeout(() => {
                this.confirmLoading = false
              }, 350)
            }
          // eslint-disable-next-line handle-callback-err
          }).catch(error => {
            this.getCode()
            setTimeout(() => {
              this.confirmLoading = false
            }, 350)
          })
        }
      })
    },
    // 获取验证码
    getCode() {
      captcha().then((res) => {
        this.image = res.data.image
        this.keys = res.data.key
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .phone-find{
    height: 100%;
    /*width: 100%;*/
    //background: $bg url('bg.png') center no-repeat;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      color: #313a46;
      padding-top: 72px;
      .phone-tip {
        width: 800px;
        height: 200px;
        padding: 35px 0 0 35px;
        margin-top: 100px;
        line-height: 30px;
        font-size: 12px;
        color: #313a46;
        border: 1px solid #ffda6b;
        background: #fbf4dd;
      }
    }
  }
</style>
